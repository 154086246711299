import { Button, Input, Modal, Textarea } from "@nextui-org/react";
import { AxiosResponse } from "axios";
import { Dispatch, SetStateAction } from "react";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { UseMutateFunction } from "react-query";

type ModalProps = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  mutate: UseMutateFunction<
    AxiosResponse<any, any>,
    any,
    TContactsData,
    unknown
  >;
};

export type TContactsData = {
  fullName: string;
  message: string;
  phoneNumber: string;
};

function ModalBlur({ isOpen, setIsOpen, mutate }: ModalProps) {
  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
    reset,
    clearErrors
  } = useForm<TContactsData>({
    defaultValues: {
      fullName: "",
      message: "",
      phoneNumber: "",
    },
  });

  const handleClose = () => {
    setIsOpen(false);
    clearErrors()
  };

  const {t} = useTranslation()

  const handleSend = (data: TContactsData) => {
    if (data.phoneNumber.split(" ").join("").length !== 9) {
      setError("phoneNumber", {
        message: t('VALID_PHONE_NUMBER') || '',
        type: "pattern",
      });
      return;
    }
    mutate({
      fullName: data.fullName,
      message: data.message,
      phoneNumber: "+998" + data.phoneNumber.split(" ").join(""),
    });
    setIsOpen(false);
    toast.loading(t('TOAST_SENDING_MES'), { duration: 1000 });

    console.log("data", data);
    reset();
  };

  console.log("errors: ", errors);
  return (
    <>
      <Modal
        css={{ margin: "0 10px" }}
        blur
        closeButton
        open={isOpen}
        onClose={handleClose}
      >
        <Modal.Header>
          <h4>{t('CONTACT_US')}</h4>
        </Modal.Header>

        <form onSubmit={handleSubmit(handleSend)}>
          <Modal.Body>
            <Controller
              name="fullName"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder={t('C_FULL_NAME') || ''}
                  clearable
                  fullWidth
                  css={{
                    marginBottom: errors.fullName?.message ? "2rem" : "1rem",
                  }}
                  status={errors.fullName ? "error" : "default"}
                  helperText={errors.fullName?.message}
                />
              )}
              rules={{
                required: {
                  message: t('FIELD_REQUIRED'),
                  value: true,
                },
              }}
            />
            <Controller
              name="phoneNumber"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder={t('C_PHONE_NUMBER') || ''}
                  type="tel"
                  fullWidth
                  labelLeft="+998"
                  maxLength={12}
                  status={errors.phoneNumber ? "error" : "default"}
                  helperText={errors.phoneNumber?.message}
                  css={{
                    marginBottom: errors.phoneNumber?.message ? "2rem" : "1rem",
                  }}
                />
              )}
              rules={{
                pattern: {
                  message: t('VALID_PHONE_NUMBER'),
                  value: /[0-9 ]/,
                },
                required: {
                  message: t('FIELD_REQUIRED'),
                  value: true,
                },
              }}
            />
            <Controller
              name="message"
              control={control}
              render={({ field }) => (
                <Textarea
                  {...field}
                  placeholder={t('C_MESSAGE') || ''}
                  fullWidth
                  status={errors.message ? "error" : "default"}
                  helperText={errors.message?.message}
                  css={{
                    marginBottom: errors.message?.message
                      ? "1rem !important"
                      : "0",
                  }}
                />
              )}
              rules={{
                required: {
                  message: t('FIELD_REQUIRED'),
                  value: true,
                },
              }}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit">{t('SEND')}</Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}

export default ModalBlur;

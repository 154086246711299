import { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { routes } from "./routes";
import Footer from "./components/footer/Footer";
import Header from "./components/header/Header";
import AOS from "aos";
import { Toaster } from 'react-hot-toast'
import "./react-i18next";

function App() {
     useEffect(() => {
          AOS.init({duration: 550});
     }, []);
     return (
          <div className="App">
               <Header />
               <Routes>
                    {routes.map((item) => {
                         return <Route {...item} key={item.id} />;
                    })}
               </Routes>
               <Toaster containerStyle={{ zIndex: "100000" }} />
               <Footer />
          </div>
     );
}

export default App;

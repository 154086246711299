import { Dropdown } from "@nextui-org/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export type languages = "uz" | "en" | "ru";

const isLang = (arg: string | null): arg is languages => {
  return ["uz", "en", "ru"].some((el) => el === arg);
};

const savedLang = localStorage.getItem("i18nextLng");

function LanguageDropdown() {
  const [currentLang, setCurrentLang] = useState<languages>(
    isLang(savedLang) ? savedLang : "uz"
  );
  const { i18n } = useTranslation();

  const handleChange = (langSet: any) => {
    const lang = Array.from(langSet)[0] as string;
    console.log("lang:", lang);
    setCurrentLang(isLang(lang) ? lang : currentLang);
    if (localStorage.getItem("i18nextLng") !== lang) {
      i18n.changeLanguage(`${lang}`);
      localStorage.setItem("i18nextLng", lang);
    }
  };

  return (
    <Dropdown>
      <Dropdown.Button
        bordered
        css={{ color: "#141414", borderColor: "#141414" }}
      >
        {currentLang.toUpperCase()}
      </Dropdown.Button>
      <Dropdown.Menu
        selectionMode="single"
        selectedKeys={currentLang}
        onSelectionChange={handleChange}
        css={{ minWidth: "initial" }}
      >
        <Dropdown.Item key="uz">UZ</Dropdown.Item>
        <Dropdown.Item key="ru">RU</Dropdown.Item>
        <Dropdown.Item key="en">EN</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default LanguageDropdown;

import { RouteProps } from "react-router-dom";
import Home from "./pages/home/Home";

export const routes: RouteProps[] = [
     {
          path: "/",
          id: "home",
          element: <Home />,
     },
];

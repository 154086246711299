import { useState, useEffect } from "react";
import {
     addBodyPaddingOnScrollRemove,
     removeBodyPaddingOnScrollRemove,
} from "../../utilities/style-utils";
import LanguageDropdown from "./LanguageDropdown";
import Menu from "./Menu";
import { MainLogo } from "./svgs";

const Body = document.getElementById("body");

const Header = () => {
     const [isOpen, setIsOpen] = useState(false);

     useEffect(() => {
          if (isOpen) {
               addBodyPaddingOnScrollRemove();
               Body?.classList.add("overflow-hidden");
          } else {
               removeBodyPaddingOnScrollRemove();
               Body?.classList.remove("overflow-hidden");
          }
     }, [isOpen]);
     return (
          <>
               <div className={`nav ${isOpen ? "open" : ""}`}>
                    <div className="container-fluid">
                         <a href="#" className="navLogo">
                              <MainLogo />
                         </a>
                         <span className="lang-menu-container d-flex align-items-center">
                              <LanguageDropdown/>
                              <div
                                   className="nav__button"
                                   onClick={() => setIsOpen((prev) => !prev)}
                                   >
                                   <span
                                        className="nav__icon"
                                        aria-label="toggle navigation menu"
                                        ></span>
                              </div>
                         </span>
                    </div>
               </div>
               <Menu isOpen={isOpen} setIsOpen={setIsOpen} />
               <div className={`menu-wrapper ${isOpen && "open"}`}></div>
          </>
     );
};

export default Header;

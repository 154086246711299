import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./styles/index.scss";
import { BrowserRouter } from "react-router-dom";
import { QueryClientProvider } from "react-query";
import { queryClient } from "./utilities/queryClient";

const root = ReactDOM.createRoot(
     document.getElementById("root") as HTMLElement
);
root.render(
     <React.StrictMode>
          <BrowserRouter>
               <QueryClientProvider client={queryClient}>
                    <App />
               </QueryClientProvider>
          </BrowserRouter>
     </React.StrictMode>
);

reportWebVitals();

// import { Container, Card, Row, Text, Link as NLink } from "@nextui-org/react";
import SimpleButton from "../../components/SimpleButton";
import LinkArrow from "../../components/LinkArrow";
// prettier-ignore
import { SVGServices1, SVGServices2, SVGServices3, SVGClients1, SVGClients2, SVGClients3, SVGClients4, SVGClients5, SVGClients6, SVGClients7, SVGClients8, SVGClients9, SVGProjectBackground, ShowcaseBGImage, GradientCircle } from "./svgs"
import ContactModal from "./ContactModal";
import Works from "./Works";
import { services } from "./texts";
import { useTranslation } from "react-i18next";

const Home = () => {
     const { t } = useTranslation()
     return (
          <>
               <section className="showcase">
                    <div className="container-fluid d-flex flex-column justify-content-center">
                         <div className="text-container">
                              <div className="top-hash">
                                   #{t('HERO_TOP')}
                              </div>
                              <h1>
                                   {t('HERO_T')}
                              </h1>
                              <a href="#lets-work">
                                   <SimpleButton css={{ padding: "25px 20px 25px 16px" }}>
                                        {t('HERO_B')}
                                   </SimpleButton>
                              </a>
                         </div>
                         <div data-aos="fade-left" className="right-image">
                              <ShowcaseBGImage />
                         </div>
                         <div className="bottom-text">{t('HERO_IN_MARKET')}</div>
                    </div>
               </section>

               <Works />

               <section id="services" className="services">
                    <div className="container-fluid">
                         <div className="center-text">
                              <h2 className="big">{t('SERVICES')}</h2>
                              <p className="desc">
                                   {t('SERV_DESC')}
                              </p>
                              <LinkArrow href="#">{t('VIEW_SERVICES')}</LinkArrow>
                         </div>

                         <div className="services-container">
                              <div data-aos="fade-left" className="service">
                                   <SVGServices1 />
                                   <h4>{t('SERV_DESIGN')}</h4>
                                   <ul>
                                        {services.design.map((s, i) => (
                                             <li key={i}>
                                                  <span>•</span>
                                                  {t(s)}
                                             </li>
                                        ))}
                                   </ul>
                              </div>
                              <div data-aos="fade-left" className="service">
                                   <SVGServices2 />
                                   <h4>{t('SERV_DESIGN')}</h4>
                                   <ul>
                                        {services.mobile.map((s, i) => (
                                             <li key={i}>
                                                  <span>•</span>
                                                  {t(s)}
                                             </li>
                                        ))}
                                   </ul>
                              </div>
                              <div data-aos="fade-left" className="service">
                                   <SVGServices3 />
                                   <h4>{t('SERV_DESIGN')}</h4>
                                   <ul>
                                        {services.development.map((s, i) => (
                                             <li key={i}>
                                                  <span>•</span>
                                                  {t(s)}
                                             </li>
                                        ))}
                                   </ul>
                              </div>
                         </div>
                    </div>
               </section>

               <section id="our-clients" className="clients">
                    <div className="container-fluid">
                         <div className="text-container">
                              <h2 className="big">{t('OUR_CLIENTS')}</h2>
                              <p className="section-desc-p">
                                   {t('CLIENTS_DESC')}
                              </p>
                         </div>
                         {/* prettier-ignore */}
                         <div data-aos="fade-right" className="clients__logo">
                              <div className="svg-wrapper"><SVGClients1 /></div>
                              <div className="svg-wrapper"><SVGClients2 /></div>
                              <div className="svg-wrapper"><SVGClients3 /></div>
                              <div className="svg-wrapper"><SVGClients4 /></div>
                              <div className="svg-wrapper"><SVGClients5 /></div>
                              <div className="svg-wrapper"><SVGClients6 /></div>
                              <div className="svg-wrapper"><SVGClients7 /></div>
                              <div className="svg-wrapper"><SVGClients8 /></div>
                              <div className="svg-wrapper"><SVGClients9 /></div>
                         </div>
                    </div>
               </section>

               <section id="lets-work" className="work-together">
                    <div className="container-fluid">
                         <div className="text-container">
                              <h2 className="big">
                                   {t('PROJECT_IN_MIND_1')}
                                   <br />
                                   {t('PROJECT_IN_MIND_2')}
                              </h2>
                              <p className="section-desc-p">
                                   {t('PROJECT_IN_MIND_DESC')}
                              </p>
                              <span
                                   data-aos="fade-down"
                                   data-aos-once="true"
                                   data-aos-delay="150"
                              >
                                   <ContactModal />
                              </span>
                         </div>
                    </div>
                    <div
                         data-aos="fade-up"
                         data-aos-delay="500"
                         className="bg-image"
                    >
                         <SVGProjectBackground />
                    </div>
               </section>
          </>
     );
};

export default Home;

import React from "react";
import { useTranslation } from "react-i18next";
import LanguageDropdown from "./LanguageDropdown";

type MenuProps = {
     setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
     isOpen: boolean;
};

const Menu = ({ setIsOpen, isOpen }: MenuProps) => {
     const handleClick = () => {
          setIsOpen(false);
     };
     const {t} = useTranslation()
     return (
          <>
               <div className={`menu ${isOpen ? "open" : "hidden"}`}>
                    <div className="menu__left">
                         <ol>
                              <li>
                                   <a onClick={handleClick} href="#works">
                                        {t('WORKS')}
                                   </a>
                              </li>
                              <li>
                                   <a onClick={handleClick} href="#services">
                                        {t('SERVICES')}
                                   </a>
                              </li>
                              <li>
                                   <a onClick={handleClick} href="#our-clients">
                                        {t('OUR_CLIENTS')}
                                   </a>
                              </li>
                              <li>
                                   <a onClick={handleClick} href="#lets-work">
                                        {t('LETS_TALK')}
                                   </a>
                              </li>
                         </ol>
                    </div>
                    <div className="menu__right">
                         <div className="up__right">
                              <ol>
                                   <p className="title">{t('FOLLOW_US')}</p>
                                   <li>
                                        <a
                                             href="https://www.instagram.com/rndd.llc"
                                             target="_blank"
                                             rel="noreferrer"
                                        >
                                             Instagram
                                        </a>
                                   </li>
                                   <li>
                                        <a
                                             href="https://t.me/click_jobs"
                                             target="_blank"
                                             rel="noreferrer"
                                        >
                                             Telegram
                                        </a>
                                   </li>
                              </ol>
                         </div>
                         <div className="down__right">
                              <ol>
                                   <p className="title">{t('GET_IN_TOUCH')}</p>
                                   <li className="phone-email">
                                        {t('PHONE_NUMBER')}
                                   </li>
                                   <li>
                                        <a href="tel:998917792221">(91) 779 22 21</a>,{' '}
                                        <a href="tel:998971972332">(97) 197 23 32</a>

                                   </li>
                                   <li className="phone-email">
                                        {t('EMAIL')}
                                   </li>
                                   <li>
                                        <a href="mailto:info@rndd.uz">info@rndd.uz</a>
                                   </li>
                              </ol>
                         </div>
                    </div>
               </div>
          </>
     );
};

export default Menu;

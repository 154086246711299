export const MainLogo = () => (
     <svg
          width="116"
          height="24"
          viewBox="0 0 116 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
     >
          <path
               d="M44.7617 23.4752H40.7423V21.6329H40.6853C40.5358 21.8941 40.3458 22.1528 40.1132 22.414C39.8805 22.6751 39.598 22.9078 39.2609 23.112C38.9261 23.3162 38.5439 23.4847 38.1165 23.6153C37.6892 23.7459 37.2239 23.81 36.7205 23.81C35.6593 23.81 34.7999 23.6462 34.1399 23.3209C33.4799 22.9957 32.9671 22.5446 32.6038 21.9677C32.2406 21.3907 31.9984 20.7117 31.8773 19.9307C31.7563 19.1496 31.6969 18.302 31.6969 17.3903V9.91187H35.8825V16.5547C35.8825 16.9464 35.8967 17.35 35.9252 17.7678C35.9537 18.1857 36.0368 18.5727 36.1769 18.9264C36.3169 19.2802 36.5306 19.5674 36.8179 19.7906C37.1052 20.0138 37.5206 20.1253 38.0595 20.1253C38.5985 20.1253 39.0353 20.028 39.3724 19.8333C39.7072 19.6386 39.9636 19.3775 40.1393 19.0522C40.315 18.727 40.4313 18.359 40.4883 17.9506C40.5453 17.5423 40.5714 17.1126 40.5714 16.6662V9.91187H44.7569V23.4752H44.7617Z"
               fill="#141414"
          />
          <path
               d="M46.8746 9.9119H50.894V11.7542H50.951C51.0816 11.4931 51.2668 11.2343 51.5089 10.9731C51.7511 10.712 52.0384 10.4793 52.3731 10.2751C52.7079 10.071 53.0901 9.9024 53.5174 9.77183C53.9448 9.64125 54.4101 9.57715 54.9134 9.57715C55.9746 9.57715 56.8293 9.74096 57.4822 10.0662C58.1327 10.3915 58.6408 10.8426 59.004 11.4195C59.3673 11.9964 59.6142 12.6754 59.7424 13.4565C59.873 14.2375 59.9371 15.0851 59.9371 15.9968V23.4776H55.7515V16.8325C55.7515 16.4407 55.7372 16.0371 55.7087 15.6193C55.6803 15.2014 55.5972 14.8145 55.4571 14.4607C55.317 14.107 55.1033 13.8197 54.8161 13.5965C54.5264 13.3734 54.1133 13.2618 53.5744 13.2618C53.0355 13.2618 52.5986 13.3591 52.2615 13.5538C51.9268 13.7485 51.6704 14.0096 51.4947 14.3349C51.3166 14.6601 51.2027 15.0281 51.1457 15.4365C51.0911 15.8448 51.0626 16.2745 51.0626 16.7209V23.4753H46.877V9.9119H46.8746Z"
               fill="#141414"
          />
          <path
               d="M108.628 23.5822H104.777V21.7969H104.72C104.552 22.039 104.333 22.286 104.065 22.5352C103.794 22.7869 103.478 23.0148 103.115 23.219C102.752 23.4232 102.353 23.5917 101.914 23.7223C101.477 23.8529 101.026 23.917 100.561 23.917C99.5564 23.917 98.6448 23.7437 97.8257 23.3994C97.0066 23.0552 96.3039 22.5708 95.7175 21.9488C95.1311 21.3244 94.68 20.5861 94.3642 19.729C94.0485 18.8743 93.8894 17.9342 93.8894 16.9109C93.8894 15.9613 94.0342 15.0544 94.3215 14.1902C94.6088 13.326 95.0195 12.5568 95.5489 11.8873C96.0783 11.2178 96.7265 10.6836 97.4886 10.2824C98.2507 9.88354 99.1172 9.68174 100.083 9.68174C100.957 9.68174 101.771 9.81706 102.526 10.0853C103.279 10.356 103.899 10.8166 104.383 11.4671H104.44V2.4834H108.626V23.5822H108.628ZM104.777 16.8017C104.777 15.7785 104.485 14.9499 103.899 14.3184C103.312 13.6869 102.488 13.3687 101.43 13.3687C100.371 13.3687 99.5469 13.6845 98.9605 14.3184C98.3741 14.9523 98.0821 15.7785 98.0821 16.8017C98.0821 17.825 98.3741 18.6535 98.9605 19.2851C99.5469 19.919 100.371 20.2347 101.43 20.2347C102.488 20.2347 103.312 19.919 103.899 19.2851C104.485 18.6512 104.777 17.825 104.777 16.8017Z"
               fill="#141414"
          />
          <path
               d="M76.3162 23.5822H72.4654V21.7969H72.4084C72.2398 22.039 72.0214 22.286 71.7531 22.5352C71.4825 22.7869 71.1667 23.0148 70.8035 23.219C70.4402 23.4232 70.0414 23.5917 69.6022 23.7223C69.1653 23.8529 68.7142 23.917 68.2489 23.917C67.2447 23.917 66.333 23.7437 65.5139 23.3994C64.6949 23.0552 63.9921 22.5708 63.4057 21.9488C62.8193 21.3244 62.3682 20.5861 62.0525 19.729C61.7367 18.8743 61.5776 17.9342 61.5776 16.9109C61.5776 15.9613 61.7225 15.0544 62.0097 14.1902C62.297 13.326 62.7077 12.5568 63.2371 11.8873C63.7666 11.2178 64.4147 10.6836 65.1768 10.2824C65.9389 9.88354 66.8055 9.68174 67.7717 9.68174C68.6454 9.68174 69.4597 9.81706 70.2147 10.0853C70.9673 10.356 71.5869 10.8166 72.0713 11.4671H72.1282V2.4834H76.3138V23.5822H76.3162ZM72.4654 16.8017C72.4654 15.7785 72.1733 14.9499 71.5869 14.3184C71.0005 13.6869 70.1767 13.3687 69.1179 13.3687C68.0566 13.3687 67.2352 13.6845 66.6488 14.3184C66.0624 14.9523 65.7703 15.7785 65.7703 16.8017C65.7703 17.825 66.0624 18.6535 66.6488 19.2851C67.2352 19.919 68.059 20.2347 69.1179 20.2347C70.1791 20.2347 71.0005 19.919 71.5869 19.2851C72.171 18.6512 72.4654 17.825 72.4654 16.8017Z"
               fill="#141414"
          />
          <path
               d="M113.143 23.848C114.696 23.848 115.954 22.5895 115.954 21.037C115.954 19.4846 114.696 18.2261 113.143 18.2261C111.591 18.2261 110.333 19.4846 110.333 21.037C110.333 22.5895 111.591 23.848 113.143 23.848Z"
               fill="#141414"
          />
          <path
               d="M14.6863 23.7626H8.20734L7.54733 22.6206L5.60768 19.2613V23.7626H0V0H7.34316C11.3982 0 14.6863 3.28816 14.6863 7.34316C14.6863 10.7215 12.4048 13.5681 9.29469 14.4228L9.44663 14.6839L11.2201 17.7561L11.448 18.1502L11.467 18.181L14.0762 22.7014L14.6863 23.7626Z"
               fill="#141414"
          />
          <path
               d="M22.699 9.27563C18.6345 9.27563 15.3416 12.5685 15.3416 16.633C15.3416 20.6952 18.6345 23.9904 22.699 23.9904C26.7611 23.9904 30.0564 20.6975 30.0564 16.633C30.0564 12.5685 26.7635 9.27563 22.699 9.27563ZM22.699 20.2583C20.6976 20.2583 19.076 18.6368 19.076 16.6354C19.076 14.634 20.6976 13.0101 22.699 13.0101C24.7003 13.0101 26.3219 14.6317 26.3219 16.633C26.3219 18.6344 24.7003 20.2583 22.699 20.2583Z"
               fill="#141414"
          />
          <path
               d="M92.6027 15.6169C92.1041 12.0391 89.032 9.28516 85.3165 9.28516C81.601 9.28516 78.5289 12.0391 78.0303 15.6169C77.9829 15.9517 77.9591 16.2959 77.9591 16.6426C77.9591 17.3524 78.0588 18.0385 78.2464 18.6867C79.1319 21.754 81.9619 23.9976 85.3165 23.9976C87.1612 23.9976 88.8468 23.3186 90.136 22.1956L86.938 19.8809C86.449 20.1254 85.8982 20.2631 85.3141 20.2631C84.0725 20.2631 82.9756 19.6387 82.3227 18.6843H92.3843C92.408 18.606 92.427 18.5276 92.4484 18.4493C92.5956 17.87 92.6739 17.2622 92.6739 16.6378C92.6739 16.2936 92.6502 15.9517 92.6027 15.6169ZM81.8408 15.6169C81.9239 15.3344 82.0402 15.0638 82.1874 14.8145C82.4509 14.3634 82.8094 13.9764 83.2368 13.6773C83.8256 13.2642 84.5425 13.0197 85.3165 13.0197C86.9618 13.0197 88.3506 14.1141 88.7922 15.6169H81.8408Z"
               fill="#141414"
          />
     </svg>
);

export const BurgerMenu = () => (
     <svg
          width="21"
          height="24"
          viewBox="0 0 21 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
     >
          <rect y="0.5" width="21" height="3" fill="#141414" />
          <rect y="10.5" width="21" height="3" fill="#141414" />
          <rect y="20.5" width="16" height="3" fill="#141414" />
          <rect x="18" y="20.5" width="3" height="3" fill="#141414" />
     </svg>
);

export const CancelIcon = () => (
     <svg
          width="23"
          height="24"
          viewBox="0 0 23 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
     >
          <g clipPath="url(#clip0_63_854)">
               <path
                    d="M0.758057 22.2571L11.2581 11.7571M21.7581 1.25708L11.2561 11.7571M11.2561 11.7571L0.758057 1.25708M11.2581 11.7571L21.7581 22.2571"
                    stroke="black"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
               />
          </g>
          <defs>
               <clipPath id="clip0_63_854">
                    <rect
                         width="23"
                         height="23"
                         fill="white"
                         transform="translate(0 0.5)"
                    />
               </clipPath>
          </defs>
     </svg>
);
